body {
    background-color: rgb(255, 255, 255) !important;
}

#content-header {
    background-image: url("/dp2b/++theme++dp2b-theme/img/dp2b-header.png");
    background-repeat: no-repeat;
    background-position: center center;
}
body.section-front-page {
    #content-header {
        background-image: url("/dp2b/++theme++dp2b-theme/img/dp2b-header-start.png");
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.navbar-nav {
    .nav-item {
        > ul > li {
            background-color: $hellblau;
        }
        &.current {
            background-color: $gruen;
        }
        &.inPath {
            background-color: $gruen;
        }
    }
}
#main-container {
    a {
        color: $dunkelblau;
    }
    a:hover,
    a:visited {
        color: lighten($dunkelblau, 10%);
    }
    a#add-user,
    a#add-user:hover,
    a#add-user:visited 
    {
        color: white;
    }
    .btn-primary,
    .btn-success,
    .btn-secondary {
        color: white;
    }

    h1 {
        color: $violett;
        font-size: 2rem;
        a {
            text-decoration: none !important;
            color: $dunkelblau !important;
        }
    }
    h2 {
        color: $gruen;
        a {
            text-decoration: none !important;
            color: $violett !important;
        }
    }
    h3 {
        color: $rot !important;
    }
}

.fb-icon {
    width: 24px;
}

#portal-footer-wrapper {
    background-color: #6a3f87;
    color: #fff;
}

#portal-footer-wrapper a {
    color: #00a1e5;
}

.portlet {
    border: none;
    box-shadow: none;
}
.portlet .portletHeader {
    background: #ffec00;
}
.portlet .portletHeader > a {
    color: #334086;
}
.portlet .portletContent > ul > li a {
    color: #334086;
}

.card.portlet {
    .card-header {
        background: #ffec00;
    }
}

/* --- logged in ----- */
body.userrole-authenticated #portal-logo {
    margin-top: 35px;
}

/* --- nav --- */
.unterstuetzen {
    background-color: #b13839 !important;
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 28px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }

    #portal-logo {
        margin-top: 30px;
    }
    #portal-logo > img {
        width: 140px;
    }
    #header-image {
        height: 260px;
    }

    #portal-languageselector {
        float: none;
        position: absolute;
        background: #ffffff90;
        padding: 5px 10px;
        right: 0;
        top: 10px;
    }
}

.pat-structure .itemRow.folder .title a.state-private {
	color: $rot;
}

table.table {
	tr.itemRow {
		&.state-private {
			td {
				color: $rot;
				a {
					color: $rot;
				}
			}
		}
	}
}
a.state-private {
	color: $rot;
}

.item {
    clear:both;
}

/* --- Startpage ----- */
.tiles-wrapper {
    .tile-col {
        float: left;
        padding: 0 15px 25px 0;
        .tile {
            background-color: #f3f4f5 !important;
            max-height: 400px;
            height: 400px;
            .tile-img {    
                img {
                    width: 100%;
                }
            }
            .tile-text {
                padding: 10px;
                h2 {
                    font-size: 1.35rem;
                    padding: 15px 0 5px 0;
                }
                p, div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: 0;
                }
            }
            .tile-footer {
                padding: 0px 10px 10px 10px;
                float: right;
                a {
                    text-decoration: none;
                }
            }
                
        }
    
    }

}